body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  font-size: 10px;
  scroll-behavior: smooth;
}
@media (max-width: 1900px) {
  html {
    font-size: 16px;
  }
}
@media (min-width: 1900px) {
  html {
    font-size: 15px;
  }
}
@media (max-width: 1500px) {
  html {
    font-size: 12px;
  }
}
@media (max-width: 1200px) {
  html {
    font-size: 10px;
  }
}
/* @media (max-width: 1000px) {
  html {
    font-size: 9px;
  }
} */
@media (max-width: 900px) {
  html {
    font-size: 9px;
  }
}
@media (max-width: 400px) {
  html {
    font-size: 8px;
  }
}
