.careerContainer {
  width: inherit;
  font-family: "Ubuntu";
  font-style: normal;
}
.headerContainer {
  padding: 0rem 8%;
  background: rgba(16, 174, 255, 0.2);
  display: flex;
}
.textHeader {
  padding: 5rem 0rem;
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 5%;
}
.textHeader h2 {
  font-weight: 500;
  font-size: 5.5rem;
  line-height: 7rem;
  color: #464646;
}
.textHeader h2:nth-child(1) {
  font-family: Ubuntu-Medium;
}
.headerPara {
  padding-top: 2.6rem;
  width: 65%;
}
.headerPara p {
  text-align: justify;
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 1.4rem;
  color: #464646;
  font-family: "Ubuntu-Medium";
}
.imgHeader {
  width: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.overlayImg {
  bottom: 0;
  position: absolute;
  width: 32rem;
  height: 32rem;
  border-radius: 50%;
  background-color: #9ddcfc;
  display: flex;
  justify-content: center;
  align-items: center;
}
.overlayIcon0 {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5rem;
  height: 5rem;
  left: 2%;
  bottom: 12%;
  z-index: 2;
}
.overlayIcon1 {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5rem;
  height: 5rem;
  left: -7%;
  top: 30%;
  z-index: 2;
}
.overlayIcon2 {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5rem;
  height: 5rem;
  left: 15%;
  top: 0%;
  z-index: 2;
}

.imgHeader img {
  position: absolute;
  bottom: 0;
  width: 100%;
  max-width: 34rem;
  object-fit: contain;
}
.overlayImg img {
  position: absolute;
  width: 100%;
  object-fit: contain;
}

.careerHeader {
  background-color: rgb(255, 255, 255);
  padding: 0rem 8%;
  padding-top: 3rem;
}
.textContainer {
  border-bottom: 1px solid #dddddd;
  padding-bottom: 4rem;
}
.textContainer h2 {
  width: 75%;
  padding-top: 1.8rem;
  font-family: "Ubuntu-Medium";
  font-weight: 500;
  font-size: 4rem;
  line-height: 4.6rem;
  color: #464646;
}
.textContainer p {
  text-align: left;
  padding-top: 1.8rem;
  width: 38%;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: #464646;
}
.jobBoard {
  margin-top: 2.6rem;
  background-color: rgb(255, 255, 255);
  padding: 0rem 8%;
}
.jobContent {
  display: grid;
  grid-template-columns: 30% 70%;
  border-bottom: 1px solid #dddddd;
}

.jobCard {
  border: 1px solid #dddddd;
  display: flex;
  justify-content: space-between;
  padding: 1.5rem;
  border-radius: 10px;
  margin-bottom: 2rem;
}
.jobRole {
  width: 55%;
}
.jobTitle h3 {
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.3rem;
  color: #464646;
  padding-bottom: 1.8rem;
  font-family: "Ubuntu-Medium";
}
.jobTitle h4 {
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.1rem;
  color: #464646;
}
.jobApply {
  width: 20%;
}
.jobRole h3 {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.3rem;
  color: #000000;
  padding-bottom: 1.8rem;
  font-family: "Ubuntu-Medium";
}
.position1 {
  background: #eeeeee;
  border-radius: 8px;
  padding: 0.6rem;
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 1.6rem;
  color: #464646;
  margin-left: 1rem;
  font-family: "Ubuntu-Medium";
}
.position2 {
  padding: 0.6rem;
  background: #eeeeee;
  border-radius: 8px;
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 1.6rem;
  color: #464646;
  font-family: "Ubuntu-Medium";
  display: none;
}
.jobRole p {
  text-align: justify;
  font-weight: 200;
  font-size: 1.5rem;
  line-height: 2.1rem;
  color: #000000;
}
.jobApply {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.jobApply span {
  font-weight: 500;
  font-size: 1.3rem;
  line-height: 2.1rem;
  text-decoration-line: underline;
}
.jobApply button {
  font-family: "Ubuntu-Medium";
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #10aeff;
  padding: 0.5rem;
  border: none;
  border-radius: 9px;
  min-width: 10rem;
  min-height: 3.5rem;
}
.jobApply a {
  color: white;
}

button:hover {
  cursor: pointer;
}

.internJobContent {
  display: grid;
  grid-template-columns: 30% 70%;
  padding: 2.6rem 0rem 0rem;
}
.feedbackContainer {
  background: rgba(16, 174, 255, 0.2);
  padding: 2rem 8%;
  width: 100%;
}

.feedbackCard {
  margin: auto;
  padding-bottom: 2rem;
  width: 25rem;
  position: relative;
}
.profileContainer {
  width: 100%;
}
.profileContainer img {
  width: 100%;
  height: 100%;
  max-height:300px;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.dummyGap {
  position: relative;
  width: 100%;
  height: 8px;
  background-color: #ebebeb;
  display: flex;
  align-items: center;
}
.quoteIcon {
  position: absolute;
  left: 8%;
  z-index: 1;
}
.quoteIcon img {
  width: 4rem;
}
.feedbackText {
  width: 100%;
  height: 20rem;
  padding: 2rem 1rem 1rem;
  background-color: white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  position: relative;
}
.reviewPara {
  width: 100%;
  color: #464646;
  overflow-x: hidden;
  overflow-y: auto;
}
.reviewPara p {
  text-align: justify;
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 1.5rem;
}
.employee {
  position: absolute;
  bottom: 3rem;
}
.employee h3 {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: #464646;
  font-family: "Ubuntu-Medium";
}
.employee h4 {
  font-style: italic;
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 1.6rem;
  color: #808080;
}
.slick-dots.visible {
  display: flex;
  justify-content: center;
  font-size: 14px;
  color: #464646;
  bottom: -1rem;
}
.slick-dots li button:before {
  color: #000;
  font-size: 1.2rem;
}

.slick-dots li.slick-active button:before {
  color: rgb(255, 255, 255);
  font-size: 1.5rem;
}
@media (max-width: 800px) {
  .feedbackCard {
    width: 22rem;
  }
  .feedbackText {
    height: 22rem;
  }
}
@media (max-width: 720px) {
  .headerContainer {
    padding: 0rem 5%;
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
  }
  .textHeader {
    margin-top: -3rem;
    padding: 4.5rem 0rem;
    width: 100%;
    margin-right: 0;
    font-size: 6rem;
    line-height: 6rem;
  }
  .headerPara {
    width: 80%;
    text-align: left;
  }
  .headerPara p {
    font-size: 1.5rem;
    line-height: 1.7rem;
  }
  .imgHeader {
    width: 100%;
    position: relative;
    padding: 19rem 0;
  }
  .imgHeader img {
    padding: 1rem 0rem;
    position: absolute;
    bottom: 0;
    width: 100%;
    max-width: 34rem;
    object-fit: contain;
  }
  .overlayImg {
    bottom: 1rem;
  }
  .textContainer h2 {
    width: 85%;
  }
  .textContainer p {
    width: 55%;
    line-height: 1.7rem;
  }
  .feedbackCard {
    width: 25rem;
  }
  .feedbackText {
    height: 20rem;
  }
}
@media (max-width: 600px) {
  .jobContent {
    grid-template-columns: 1fr;
    grid-row-gap: 4rem;
  }

  .internJobContent {
    grid-template-columns: 1fr;
    grid-row-gap: 4rem;
  }
  .textContainer h2 {
    width: 95%;
  }
  .textContainer p {
    width: 90%;
    font-size: 1.5rem;
    line-height: 1.8rem;
  }
  .jobCard {
    display: grid;
    grid-template-columns: 70% 30%;
  }
  .position2 {
    display: block;
  }
  .position1 {
    display: none;
  }
  .jobRole {
    width: 100%;
  }
  .jobApply {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
  }
  .jobApply button {
    min-width: 7.3rem;
    min-height: 3.6rem;
  }
  .jobApply span {
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 2rem;
    text-decoration-line: underline;
  }
}
@media (max-width: 320px) {
  .imgHeader {
    transform: scale(0.7);
    padding: 17rem 0;
  }
  .overlayImg {
    width: 33rem;
    height: 33rem;
  }
  .textHeader {
    margin-top: -6rem;
    padding: 6rem 0rem;
  }
  .headerPara {
    width: 95%;
  }
  .headerPara p {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .jobApply button {
    min-width: 7rem;
    min-height: 3.5rem;
  }
  .jobApply span {
    font-weight: 500;
    font-size: 1.1rem;
    line-height: 2rem;
    text-decoration-line: underline;
  }
}
