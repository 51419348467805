.downloadPage {
  width: inherit;
  font-family: "Ubuntu";
  font-style: normal;
  min-height: 80vh;
}
.mainContent {
  background: linear-gradient(180deg, #36d1dc 0%, #10aeff 100%);
  padding: 6rem 8%;
  display: flex;
  min-height: 69vh;
}

.gap {
  width: 100%;
  height: 1.2rem;
  background-color: #ffffff;
}
.appLink {
  width: 65%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.linkText {
  padding-bottom: 4.8rem;
}
.linkText h2 {
  font-weight: 700;
  font-size: 5rem;
  line-height: 6rem;
  color: #ffffff;
}
.linkText p {
  color: white;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.4rem;
  padding-top: 1.3rem;
  font-family: "Ubuntu-Medium";
}
.storeLogo {
  display: flex;
  flex-wrap: wrap;
}
.playStore {
  margin-right: 1rem;
}
.storeLogo img {
  cursor: pointer;
  max-width: 23rem;
  object-fit: contain;
}

.iphone {
  margin-top: -4rem;
  width: 35%;
  position: relative;
}
.iphone img {
  position: absolute;
  top: 0;
  right: 0;
  height: 125%;
  object-fit: contain;
}
.phoneZigZag {
  display: none;
}
@media (max-width: 620px) {
  .mainContent {
    padding: 4rem 5%;
    background: none;
    background-color: #ffffff;
    box-shadow: 0px 4px 66px rgba(109, 109, 109, 0.12) inset;
    display: flex;
    flex-direction: column;
  }
  .appLink {
    width: 100%;
  }
  .linkText {
    width: 100%;
  }
  .linkText h2 {
    color: #464646;
    line-height: 7rem;
  }
  .linkText p {
    color: #464646;
    font-size: 1.3rem;
    line-height: 1.4rem;
    padding-top: 1.8rem;
  }
  .iphone {
    display: none;
  }
  .storeLogo {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
  }
  .storeLogo img {
    width: 100%;
    max-width: 25rem;
    object-fit: contain;
    box-shadow: 0px 1.51319px 27.994px rgba(16, 174, 255, 0.07);
    border-radius: 7.56594px;
  }
  .phoneZigZag {
    margin-left: -5%;
    display: block;
    position: relative;
    width: 100vw;
    padding: 11rem 0rem;
    display: flex;
  }
  .phoneZigZag img {
    width: 6.6rem;
    height: 14.5rem;
  }
  .mini0 {
    position: absolute;
    left: -2.74%;
    top: 1.64%;
  }
  .mini0 img {
    border: 0.0612723px solid #000000;
    border-radius: 6.12723px;
  }
  .mini1 {
    position: absolute;
    left: 20%;
    top: 35%;
  }
  .mini2 {
    position: absolute;
    left: 65%;
    top: 34%;
  }
  .mini3 {
    position: absolute;
    left: 42%;
    top: 3.63px;
  }
  .mini4 {
    position: absolute;
    right: -1px;
    top: -0.5px;
  }

  .mini4 img {
    box-shadow: inset 0px 0px 0.403394px rgba(0, 0, 0, 0.35);
    border-radius: 7.4628px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }
}
@media (max-width: 520px) {
  .storeLogo {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .storeLogo img {
    width: 100%;
    max-width: 20rem;
    object-fit: contain;
    margin: 0.5rem 0rem;
    box-shadow: 0px 1.51319px 27.994px rgba(16, 174, 255, 0.07);
    border-radius: 7.56594px;
  }
}
@media (max-width: 300px) {
  .phoneZigZag img {
    width: 5rem;
    height: 12rem;
  }
}
