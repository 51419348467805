.termsHeading {
    color: #6E6E6E;
    text-align: justify;
    padding-top: 20px;
    font-weight: 700;
}

.termsContent {
    font-weight: 300;
    padding-top: 20px;
}