.parent-badges-container {
    height: 100vh;
    width: 100vw;
    width: inherit;
    position: relative;
    // border:1px solid red;
    font-family: SF PRO DISPLAY BOLD;

    .circle-container {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        position: absolute;
        width: 100%;
        // top: 35%;
        height: 100%;

        .center-circle {
            z-index: 1;
            background-color: transparent;
            height: 11%;
            width: 17%;

            p {
                font-weight: bold;
                color: #555555;
                text-align: center;
                font-weight: bold;
                z-index: 10;
                @media screen and (max-width: 699px) {
                    font-size: 25px;
                }
                @media screen and (min-width: 700px) and (max-width: 1300px) {
                    font-size: 40px;
                }
                @media screen and (min-width: 1301px) and (max-width: 1700px) {
                    font-size: 45px;
                }
                @media screen and (min-width: 1701px) {
                    font-size: 65px;
                }
            }
        }

        .side-circles {
            // background-color: #ed752f;
            border-radius: 50%;
        }
        .circle-one-left {
            position: relative;
        }

        .circle-two-left {
            position: relative;
        }

        .circle-three-left {
            position: relative;
        }

        .circle-one-right {
            position: relative;
        }
        .circle-two-right {
            position: relative;
        }
        .circle-three-right {
            position: relative;
        }
    }

    .badge-content-container {
        position: relative;
        color: #808080;
        font-weight: bold;
        text-align: center;
        margin: auto;

        @media screen and (max-width: 699px) {
            font-size: 20px;
            width: 80%;
        }
        @media screen and (min-width: 700px) and (max-width: 1099px) {
            font-size: 20px;
            width: 75%;
        }
        @media screen and (min-width: 1100px) and (max-width: 1399px) {
            font-size: 20px;
            width: 65%;
        }
        @media screen and (min-width: 1400px) and (max-width: 1700px) {
            font-size: 25px;
            width: 55%;
        }
        @media screen and (min-width: 1701px) {
            font-size: 35px;
            width: 65%;
        }
    }

    .explore-more-container {
        border: 1px solid #7b7b7b;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 86%;

        .plus {
            color: #7b7b7b;
            text-align: center;

            @media screen and (min-width: 700px) and (max-width: 1500px) {
                font-size: 35px;
            }
            @media screen and (min-width: 1501px) {
                font-size: 50px;
            }

            &:hover {
                @media screen and (min-width: 700px) and (max-width: 1500px) {
                    font-size: 15px;
                }
                @media screen and (min-width: 1501px) {
                    font-size: 21px;
                }
            }
        }

        @media screen and (max-width: 699px) {
            left: 85%;
        }
        @media screen and (min-width: 700px) and (max-width: 1099px) {
            left: 85%;
        }
        @media screen and (min-width: 1100px) and (max-width: 1399px) {
            left: 83%;
        }
        @media screen and (min-width: 1400px) and (max-width: 1700px) {
            left: 82%;
        }
        @media screen and (min-width: 1701px) {
            left: 85%;
        }
    }
}
