.earthMedia-parent-div {
    // overflow: hidden;
    // border: 1px solid red;
    width: 100vw;
    width: inherit;
    height: inherit;
    overflow: hidden;
    background-color: white;
    font-family: SF PRO DISPLAY BOLD;

    .earthMedia-div {
        border: 1px solid #707070;
        position: relative;
        display: flex;
        justify-content: space-between;
        height: 97%;
        border-top-left-radius: 13%;
        border-top-right-radius: 13%;
        top: 16.1%;
        @media screen and (max-width: 799px) {
            width: 80%;
            left: 12%;
            padding: 20px;
        }

        @media screen and (min-width: 800px) and (max-width: 1199px) {
            width: 65%;
            left: 17%;
            padding: 25px;
        }

        @media screen and (min-width: 1200px) and (max-width: 1700px) {
            width: 55%;
            left: 22%;
            padding: 25px;
            top: 16.1%;
        }

        @media screen and (min-width: 1701px) {
            width: 50%;
            padding: 37px;
            left: 25%;
        }

        .col-1 {
            width: 49%;
            height: 91%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .svg-div {
                position: absolute;
                top: 0%;
                left: 0%;

                @media screen and (max-width: 699px) {
                    width: 40%;
                    height: 40%;
                }
                @media screen and (min-width: 700px) and (max-width: 1299px) {
                    width: 40%;
                    height: 40%;
                }
                @media screen and (min-width: 1300px) and (max-width: 1700px) {
                    width: 40%;
                    height: 40%;
                }

                @media screen and (min-width: 1701px) {
                    width: 40%;
                    height: 35%;
                }

                svg {
                    height: 100%;
                    width: 100%;
                    position: absolute;
                }
            }

            .img-1-div-parent {
                position: relative;

                @media screen and (max-width: 699px) {
                    border-radius: 25px;
                }

                @media screen and (min-width: 700px) and (max-width: 1699px) {
                    border-radius: 30px;
                }

                @media screen and (min-width: 1700px) {
                    border-radius: 35px;
                }

                .img-11-div {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    overflow: hidden;
                    z-index: 1;

                    @media screen and (max-width: 699px) {
                        border-radius: 25px;
                    }

                    @media screen and (min-width: 700px) and (max-width: 1699px) {
                        border-radius: 30px;
                    }

                    @media screen and (min-width: 1700px) {
                        border-radius: 35px;
                    }

                    img {
                        width: 100%;
                        height: 100%;

                        @media screen and (max-width: 699px) {
                            border-radius: 25px;
                        }

                        @media screen and (min-width: 700px) and (max-width: 1699px) {
                            border-radius: 30px;
                        }

                        @media screen and (min-width: 1700px) {
                            border-radius: 35px;
                        }
                    }

                    p {
                        position: absolute;
                        font-weight: bold;
                        color: white;
                        text-align: left;

                        @media screen and (max-width: 699px) {
                            width: 75%;
                            font-size: 25px;
                            top: 25%;
                            left: 10%;
                            line-height: 30px;
                        }
                        @media screen and (min-width: 700px) and (max-width: 1099px) {
                            width: 70%;
                            font-size: 25px;
                            top: 21%;
                            left: 10%;
                            line-height: 35px;
                        }
                        @media screen and (min-width: 1100px) and (max-width: 1399px) {
                            width: 75%;
                            font-size: 30px;
                            top: 20%;
                            left: 10%;
                            line-height: 41px;
                        }
                        @media screen and (min-width: 1400px) and (max-width: 1699px) {
                            width: 71%;
                            font-size: 35px;
                            top: 18%;
                            left: 10%;
                            line-height: 45px;
                        }

                        @media screen and (min-width: 1700px) {
                            width: 75%;
                            font-size: 41px;
                            top: 21%;
                            left: 10%;
                            line-height: 50px;
                        }
                    }
                }

                .img-12-div {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    clip-path: polygon(43% 100%, 37% 83%, 50% 83%);

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            .img-2-div-parent {
                position: relative;

                @media screen and (max-width: 699px) {
                    border-top-right-radius: 25px;
                    border-top-left-radius: 25px;
                }

                @media screen and (min-width: 700px) and (max-width: 1699px) {
                    border-top-right-radius: 30px;
                    border-top-left-radius: 30px;
                }

                @media screen and (min-width: 1700px) {
                    border-top-right-radius: 35px;
                    border-top-left-radius: 35px;
                }

                .img-21-div {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    z-index: 1;

                    img {
                        // border-radius: 35px;
                        width: 100%;
                        height: 100%;

                        @media screen and (max-width: 699px) {
                            border-top-right-radius: 25px;
                            border-top-left-radius: 25px;
                        }

                        @media screen and (min-width: 700px) and (max-width: 1699px) {
                            border-top-right-radius: 30px;
                            border-top-left-radius: 30px;
                        }

                        @media screen and (min-width: 1700px) {
                            border-top-right-radius: 35px;
                            border-top-left-radius: 35px;
                        }
                    }

                    @media screen and (max-width: 699px) {
                        border-top-right-radius: 25px;
                        border-top-left-radius: 25px;
                    }

                    @media screen and (min-width: 700px) and (max-width: 1699px) {
                        border-top-right-radius: 30px;
                        border-top-left-radius: 30px;
                    }

                    @media screen and (min-width: 1700px) {
                        border-top-right-radius: 35px;
                        border-top-left-radius: 35px;
                    }
                }

                .img-22-div {
                    // overflow: hidden;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    clip-path: polygon(43% 100%, 37% 83%, 50% 83%);

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }

        .col-2 {
            width: 49%;
            height: 88%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .svg-div {
                position: absolute;

                @media screen and (max-width: 699px) {
                    width: 40%;
                    height: 40%;
                }
                @media screen and (min-width: 700px) and (max-width: 1299px) {
                    width: 40%;
                    height: 40%;
                }
                @media screen and (min-width: 1300px) and (max-width: 1700px) {
                    width: 40%;
                    height: 40%;
                }

                @media screen and (min-width: 1701px) {
                    width: 40%;
                    height: 35%;
                }

                svg {
                    height: 100%;
                    width: 100%;
                    position: absolute;
                }
            }

            .img-1-div-parent {
                position: relative;
                display: flex;
                justify-content: space-between;
                z-index: 1;
                @media screen and (max-width: 699px) {
                    border-radius: 25px;
                }

                @media screen and (min-width: 700px) and (max-width: 1699px) {
                    border-radius: 30px;
                }

                @media screen and (min-width: 1700px) {
                    border-radius: 35px;
                }

                .img-11-div {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    img {
                        width: 100%;
                        height: 100%;
                        @media screen and (max-width: 699px) {
                            border-radius: 25px;
                        }

                        @media screen and (min-width: 700px) and (max-width: 1699px) {
                            border-radius: 30px;
                        }

                        @media screen and (min-width: 1700px) {
                            border-radius: 35px;
                        }
                    }

                    @media screen and (max-width: 699px) {
                        border-radius: 25px;
                    }

                    @media screen and (min-width: 700px) and (max-width: 1699px) {
                        border-radius: 30px;
                    }

                    @media screen and (min-width: 1700px) {
                        border-radius: 35px;
                    }
                }

                .img-12-div {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    clip-path: polygon(43% 100%, 37% 83%, 50% 83%);

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            .img-2-div-parent {
                position: relative;

                @media screen and (max-width: 699px) {
                    border-radius: 25px;
                }

                @media screen and (min-width: 700px) and (max-width: 1699px) {
                    border-radius: 30px;
                }

                @media screen and (min-width: 1700px) {
                    border-radius: 35px;
                }

                .img-21-div {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    z-index: 1;

                    @media screen and (max-width: 699px) {
                        border-radius: 25px;
                    }

                    @media screen and (min-width: 700px) and (max-width: 1699px) {
                        border-radius: 30px;
                    }

                    @media screen and (min-width: 1700px) {
                        border-radius: 35px;
                    }

                    img {
                        width: 100%;
                        height: 100%;

                        @media screen and (max-width: 699px) {
                            border-radius: 25px;
                        }

                        @media screen and (min-width: 700px) and (max-width: 1699px) {
                            border-radius: 30px;
                        }

                        @media screen and (min-width: 1700px) {
                            border-radius: 35px;
                        }
                    }
                }
                .img-22-div {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    clip-path: polygon(43% 100%, 37% 83%, 50% 83%);

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }

    .earthMedia-content-div {
        position: relative;
        color: #7b7b7b;
        font-family: -apple-system, BlinkMacSystemFont, sans-serif;
        text-align: left;
        font-weight: bold;

        @media screen and (max-width: 769px) {
            font-size: 20px;
            top: -60%;
            width: 40%;
            line-height: 20px;
        }
        @media screen and (min-width: 770px) and (max-width: 1099px) {
            font-size: 23px;
            top: -60%;
            width: 40%;
            line-height: 27px;
        }
        @media screen and (min-width: 1100px) and (max-width: 1299px) {
            font-size: 30px;
            top: -40%;
            width: 45%;
            line-height: 35px;
        }
        @media screen and (min-width: 1300px) and (max-width: 1699px) {
            font-size: 35px;
            // top: -50%;
            top: -70%;
            width: 45%;
            line-height: 40px;
        }
        @media screen and (min-width: 1700px) {
            font-size: 41px;
            top: -45%;
            width: 44%;
            line-height: 49px;
            // width: 70%;
        }
    }
}
