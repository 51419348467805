.appInfo {
  width: inherit;
  height: 300vh;
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  position: relative;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.appInfo::-webkit-scrollbar {
  display: none;
}
.slider {
  height: 100vh;
  width: inherit;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  position: absolute;
}
.observerLayer {
  height: 100vh;
  font-size: 30px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}
.observerLayer div {
  text-align: center;
  width: 100%;
}
.up {
  color: black;
}
.down {
  color: green;
}
.cloudTop {
  top: 0px;
  left: 0px;
  width: 100%;
  position: absolute;
  height: 30vh;
  background: transparent linear-gradient(0deg, #80808000 0%, #c9c9c900 4%, #ffffff00 8%, #ffffff 50%, #ffffff 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
  z-index: 3;
}
.cloudBottom {
  bottom: 0;
  left: 0;
  width: 100%;
  position: absolute;
  height: 30vh;
  background: transparent;
  background: linear-gradient(180deg, #80808000 0%, #c9c9c900 4%, #ffffff00 8%, #ffffff 50%, #ffffff 100%) 0% 0%
    no-repeat padding-box;
  opacity: 1;
  z-index: 3;
}
.window {
  padding-left: 8%;
  width: 100%;
  position: absolute;
  z-index: 1;
  opacity: 0;
}
.windowOne {
  padding-left: 8%;
  width: 100%;
  position: absolute;
  z-index: 1;
  opacity: 1;
}

.panel1 {
  display: flex;
  flex-direction: column;
  font-size: 3rem;
  color: #7b7b7b;
  font-family: SF PRO DISPLAY BOLD;
  width: 100%;
}
.panel2 {
  display: flex;
  flex-direction: column;
  font-size: 3rem;
  color: #7b7b7b;
  font-family: SF PRO DISPLAY BOLD;
  width: 100%;
}
.panel3 {
  display: flex;
  font-size: 3rem;
  color: #7b7b7b;
  font-family: SF PRO DISPLAY BOLD;
  width: 100%;
}
.highlightText {
  color: rgb(253, 141, 44);
}
