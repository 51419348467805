.zinkuSectionContainer {
  min-height: 120vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 2vw;
}

.zinkuItem {
  height: 50vh;
  width: auto;
  position: absolute;
  z-index: 2;
  left: calc(50vw - 25vh);
  top: 25vh;
}

.topSectionTitle {
  position: relative;
  z-index: 6;
  color: white;
  font-family: rockwell;
}

.zinkuItem img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.zinkuBackground {
  width: 3vw;
  height: 1vh;
  position: absolute;
  z-index: 3;
  left: 48.5vw;
  top: 49.5vh;
  background-color: #272727;
}

.zinkuRadar {
  position: absolute;
  left: calc(50vw - 670px);
  top: calc(50vh - 670px);
  z-index: 6;
}

.afterContainer {
  height: 100vh;
  width: 100vw;
  background-color: black;
  position: absolute;
}

.zinkuText {
  position: absolute;
  z-index: 3;
  width: 50vw;
  left: 45vw;
  top: 30vh;
  padding: 30px;
  color: white;
  font-size: 46px;
  font-weight: bold;
  font-family: "ubuntu-bold";
  text-align: center;
}

.topSectionContainer {
  min-height: 100vh;
  background-color: black;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.spotlightImg {
  position: absolute;
  z-index: 3;
  height: 100vh;
  width: 100vw;
}

.blurGlass {
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: 4;
  backdrop-filter: blur(50px);
  background: rgba(255, 255, 255, 0.1);
}

@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .blurGlass {
    -webkit-backdrop-filter: blur(50px);
    backdrop-filter: blur(50px);
  }
}

.allMobileContainer {
  min-height: 80vh;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}

.allMobileContainer h1 {
  padding-top: 10vh;
  color: #707070;
  font-size: 46px;
  font-weight: bold;
  font-family: "ubuntu-bold";
  width: 70vw;
  text-align: center;
  overflow: hidden;
}

.mobileCards {
  height: 25vh;
  overflow: hidden;
}

.fullScreen {
  /* min-height: 100vh; */
  position: absolute;
}

.logoHeaderWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logImage {
  height: 15vh;
}

.carouselCard {
  height: 100%;
  margin-right: 15px;
}

.carouselImg {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.corouselContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.corousel-div-1 {
  height: 25%;
  width: 100%;
  display: flex;
  /* justify-content: space-evenly; */
  margin-bottom: 20px;
  overflow-y: hidden;
}

.corousel-div-2 {
  height: 39%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  overflow-y: hidden;
}

.corousel-text {
  font-size: 37px;
  background-color: #ff6f00;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.corouselParentDiv {
  position: relative;
  width: 100%;
  height: 100%;
}

.corouselCategory1 {
  width: 5%;
}
.corouselCategory2 {
  width: 10%;
}
.corouselCategory3 {
  width: 15%;
}
.corouselCategory4 {
  width: 8%;
  border-radius: 25px;
}
.corouselCategory5 {
  width: 20%;
}
.carouselWrapper {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  margin-top: 50px;
  margin-bottom: 50px;
}

@media screen and (max-width: 600px) {
  .corouselCategory4 {
    border-radius: 25px;
  }
  .corouselContainer {
    width: 2000px;
    height: 100%;
  }
  .carouselWrapper {
    height: 100vh;
  }
  .carouselCard,
  .carouselImg {
    border-radius: 25px;
  }
  .corousel-text {
    font-size: 15px;
  }
  .corousel-div-1 {
    justify-content: unset;
  }
  .carouselCard {
    margin-right: 10px;
  }
  .primary {
    animation: primary 70s linear infinite;
  }
  @keyframes primary {
    from {
      left: 0%;
    }
    to {
      left: -1600px;
    }
  }
}
@media screen and (min-width: 601px) {
  .corouselCategory4 {
    border-radius: 30px;
  }
  .corouselContainer {
    width: 2300px;
    height: 85%;
  }
  .carouselCard,
  .carouselImg {
    border-radius: 30px;
  }
  .corousel-text {
    font-size: 20px;
  }
  .primary {
    animation: primary 70s linear infinite;
  }
  @keyframes primary {
    from {
      left: 0%;
    }
    to {
      left: -1600px;
    }
  }
}
@media screen and (min-width: 1024px) {
  .corouselCategory4 {
    border-radius: 30px;
  }
  .corouselContainer {
    width: 2500px;
    height: 80%;
  }
  .carouselCard,
  .carouselImg {
    border-radius: 30px;
  }
  .corousel-text {
    font-size: 25px;
  }
}

@media screen and (min-width: 1280px) {
  .corouselCategory4 {
    width: 8%;
    border-radius: 46px;
  }
  .corouselContainer {
    width: 3000px;
    height: 90%;
  }
  .carouselCard,
  .carouselImg {
    border-radius: 46px;
  }
  .corousel-text {
    font-size: 25px;
  }
}

@media screen and (min-width: 1536px) {
  .corouselCategory4 {
    border-radius: 56px;
  }
  .corouselContainer {
    width: 4700px;
    height: 100%;
  }
  .carouselCard,
  .carouselImg {
    border-radius: 56px;
  }
  .corousel-text {
    font-size: 37px;
  }
  @keyframes primary {
    from {
      left: 0%;
    }
    to {
      left: -3400px;
    }
  }
}

@media (max-width: 600px) {
  .logImage {
    height: 9vh;
  }
  .allMobileContainer h1 {
    font-size: 30px;
  }
  .zinkuItem {
    width: 75vw;
    height: auto;
    left: 12.5vw;
    top: calc(50vh - 37.5vw);
  }
  .zinkuRadar {
    width: auto;
    height: 100vh;
    left: 0;
    top: 0;
  }
  .zinkuRadar img {
    width: 140%;
    height: 64%;
    object-fit: cover;
  }
  .zinkuText {
    font-size: 20px;
    top: 10vh;
    width: 90vw;
    left: 5vw;
  }
  .topSectionTitle {
    font-size: 14px;
  }
}
