.parent-badgesMedia-container {
    height: inherit;
    width: 100vw;
    width: 100%;
    overflow: hidden;
    background-color: white;
    font-family: SF PRO DISPLAY BOLD;

    .badges-media-container {
        overflow: hidden;
        height: 100%;
        width: 100%;

        .circle-container {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            position: absolute;
            width: 100%;
            height: 100%;

            .center-circle {
                z-index: 1;
                background-color: #ed752f;
                border-radius: 50%;

                @media screen and (max-width: 1099px) {
                    height: 140px;
                    width: 140px;
                }
                @media screen and (min-width: 1100px) and (max-width: 1700px) {
                    height: 160px;
                    width: 160px;
                }
                @media screen and (min-width: 1701px) {
                    height: 230px;
                    width: 230px;
                }

                p {
                    font-weight: bold;
                    color: #555555;
                    text-align: center;
                    font-weight: bold;
                    z-index: 10;
                    position: relative;

                    @media screen and (max-width: 699px) {
                        font-size: 30px;
                        top: -110px;
                    }
                    @media screen and (min-width: 700px) and (max-width: 1300px) {
                        font-size: 40px;
                        top: -110px;
                    }
                    @media screen and (min-width: 1301px) and (max-width: 1700px) {
                        font-size: 45px;
                        top: -110px;
                    }
                    @media screen and (min-width: 1701px) {
                        font-size: 65px;
                        top: -150px;
                    }
                }
            }

            .circle {
                background-color: #ed752f;
                border-radius: 50%;
                position: relative;
            }

            .circle-one-left {
                @media screen and (max-width: 1099px) {
                    height: 110px;
                    width: 110px;
                }
                @media screen and (min-width: 1100px) and (max-width: 1700px) {
                    height: 130px;
                    width: 130px;
                }
                @media screen and (min-width: 1701px) {
                    height: 180px;
                    width: 180px;
                }
            }
            .circle-two-left {
                position: relative;
                @media screen and (max-width: 1099px) {
                    height: 80px;
                    width: 80px;
                }
                @media screen and (min-width: 1100px) and (max-width: 1700px) {
                    height: 100px;
                    width: 100px;
                }
                @media screen and (min-width: 1701px) {
                    height: 160px;
                    width: 160px;
                }

                .inner-container {
                    position: relative;
                    // width: 100%;
                    // height: 100%;
                    background-color: #ed752f;
                    z-index: 10;
                    overflow: hidden;
                }
            }
            .circle-three-left {
                z-index: 1;
                @media screen and (max-width: 1099px) {
                    height: 60px;
                    width: 60px;
                }
                @media screen and (min-width: 1100px) and (max-width: 1700px) {
                    height: 80px;
                    width: 80px;
                }
                @media screen and (min-width: 1701px) {
                    height: 140px;
                    width: 140px;
                }
            }

            .circle-one-right {
                @media screen and (max-width: 1099px) {
                    height: 110px;
                    width: 110px;
                }
                @media screen and (min-width: 1100px) and (max-width: 1700px) {
                    height: 130px;
                    width: 130px;
                }
                @media screen and (min-width: 1701px) {
                    height: 180px;
                    width: 180px;
                }
            }
            .circle-two-right {
                @media screen and (max-width: 1099px) {
                    height: 80px;
                    width: 80px;
                }
                @media screen and (min-width: 1100px) and (max-width: 1700px) {
                    height: 100px;
                    width: 100px;
                }
                @media screen and (min-width: 1701px) {
                    height: 160px;
                    width: 160px;
                }
                .inner-container {
                    position: relative;
                    // width: 100%;
                    // height: 100%;
                    background-color: #ed752f;
                    z-index: 10;
                    overflow: hidden;
                }
            }
            .circle-three-right {
                @media screen and (max-width: 1099px) {
                    height: 60px;
                    width: 60px;
                }
                @media screen and (min-width: 1100px) and (max-width: 1700px) {
                    height: 80px;
                    width: 80px;
                }
                @media screen and (min-width: 1701px) {
                    height: 140px;
                    width: 140px;
                }
            }
        }

        .badge-media-content-container {
            position: relative;
            color: #808080;
            font-weight: bold;
            text-align: center;
            margin: auto;
            // top: 70.3%;

            @media screen and (min-width: 700px) and (max-width: 1099px) {
                font-size: 20px;
                width: 75%;
                top: 70%;
            }
            @media screen and (min-width: 1100px) and (max-width: 1399px) {
                font-size: 20px;
                width: 65%;
                top: 70%;
            }
            @media screen and (min-width: 1400px) and (max-width: 1700px) {
                font-size: 25px;
                width: 55%;
                top: 70%;
            }
            @media screen and (min-width: 1701px) {
                font-size: 35px;
                width: 65%;
                top: 67%;
            }
        }

        .explore-more-container {
            border: 1px solid #7b7b7b;
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            top: 86%;

            .plus {
                color: #7b7b7b;
                text-align: center;

                @media screen and (min-width: 700px) and (max-width: 1500px) {
                    font-size: 35px;
                }
                @media screen and (min-width: 1501px) {
                    font-size: 50px;
                }

                &:hover {
                    @media screen and (min-width: 700px) and (max-width: 1500px) {
                        font-size: 15px;
                    }
                    @media screen and (min-width: 1501px) {
                        font-size: 21px;
                    }
                }
            }

            @media screen and (min-width: 700px) and (max-width: 1099px) {
                left: 85%;
            }
            @media screen and (min-width: 1100px) and (max-width: 1399px) {
                left: 83%;
            }
            @media screen and (min-width: 1400px) and (max-width: 1700px) {
                left: 82%;
            }
            @media screen and (min-width: 1701px) {
                left: 85%;
            }
        }

        .empty-media-parent-div {
            overflow: hidden;
            border: 1px solid red;
            position: relative;
            height: 20%;
            width: 50%;
            margin: auto;
            border: 1px solid #707070;
            border-top-left-radius: 102px;
            // z-index: -100;
            border-top-right-radius: 102px;

            @media screen and (min-width: 700px) and (max-width: 1099px) {
                width: 75%;
                top: 80%;
            }
            @media screen and (min-width: 1100px) and (max-width: 1399px) {
                width: 60%;
                top: 80%;
            }
            @media screen and (min-width: 1300px) and (max-width: 1700px) {
                top: 80%;
                width: 55%;
            }
            @media screen and (min-width: 1701px) {
                width: 50%;
                top: 83%;
            }
        }
    }
}
