.aboutContainer {
  background-color: white;
  text-align: left;
  padding: 2rem 8% 8rem;
  font-size: 1.2rem;
  font-family: "Ubuntu";
  font-style: normal;
  width: inherit;
  box-shadow: 0px 4px 66px rgba(109, 109, 109, 0.12) inset;
  min-height: 80vh;
}
.valueContainer {
  margin-top: 3rem;
}
.valueContainer h2 {
  font-weight: 500;
  font-size: 2.2rem;
  line-height: 2.5rem;
  font-family: "Ubuntu-Medium";
}
.valueContent {
  display: grid;
  grid-template-columns: 22px auto;
  grid-row-gap: 1.2rem;
  margin-top: 2rem;
}
.dot {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #ffa056;
  display: inline-block;
}
.dotContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.missionContainer h2 {
  font-family: "Ubuntu-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 2.2rem;
  line-height: 2.5rem;
  color: #000000;
  padding-bottom: 1.8rem;
}
p {
  text-align: justify;
  font-size: 1.6rem;
  line-height: 1.8rem;
  font-weight: 400;
  color: #464646;
}
section h3 {
  font-family: "Ubuntu-Medium";
  font-weight: 900;
  font-size: 1.8rem;
  line-height: 2.1rem;
  color: #ffa056;
}
@media (max-width: 550px) {
  .aboutContainer {
    padding: 2rem 5%;
  }
}
