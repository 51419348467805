.questionItem {
  margin-top: 3rem;
}
.questionHeader {
  font-family: "Ubuntu";
  font-style: normal;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.questionHeader h2 {
  font-family: "Ubuntu-Medium";
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2.1rem;
  color: #464646;
}

.expandButton {
  position: relative;
  font-weight: 400;
  font-size: 1.1rem;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: 2px solid #464646;
  background: none;
  transition: all 0.5s ease;
}
.expandButton.active {
  border: 2px solid #10aeff;
}
.expandButton:hover {
  cursor: pointer;
}
.expandButton span::before {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1.2rem;
  height: 0.2rem;
  background: #464646;
  border-radius: 0.3rem;
  transition: all 0.5s ease;
}
.expandButton span::after {
  position: absolute;
  top: 51%;
  left: 51%;
  transform: translate(-50%, -50%);
  content: "";
  width: 0.2rem;
  height: 1.2rem;
  background: #464646;
  border-radius: 0.3rem;
  transition: all 0.5s ease;
}
.expandButton span.active::before {
  background-color: #10aeff;
  /* transform: rotate(90deg); */
  transition: all 0.5s ease;
}
.expandButton span.active::after {
  background-color: #10aeff;
  height: 0;
  transition: all 0.5s ease;
}
#answerContent {
  margin-top: 3.15rem;
  height: 0px;
  opacity: 0;
  overflow: hidden;
  transition: opacity 200ms linear, height 200ms linear;
}
#answerContent p {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.8rem;
  color: #939393;
}
.fadedBorder1 {
  margin-top: -1rem;
  border-bottom: 1.4px solid #dddddd;
}
.fadedBorder2 {
  margin-top: 3rem;
  border-bottom: 1.4px solid #dddddd;
}
