.earth-parent-div {
    overflow: hidden;
    width: inherit;
    height: inherit;
    background-color: white;
    font-family: SF PRO DISPLAY BOLD;

    .earth-div {
        position: relative;
        display: flex;
        justify-content: space-between;
        border: 1px solid #707070;

        @media screen and (max-width: 699px) {
            left: -12%;
            top: 27%;
            border-radius: 50%;
            background-color: #8ddcff;
            width: 425px;
            height: 425px;
        }

        @media screen and (min-width: 700px) and (max-width: 999px) {
            left: -12%;
            top: 27%;
            border-radius: 50%;
            background-color: #8ddcff;
            width: 525px;
            height: 525px;
        }

        @media screen and (min-width: 1000px) and (max-width: 1299px) {
            left: -12%;
            top: 30%;
            border-radius: 50%;
            background-color: #8ddcff;
            width: 625px;
            height: 625px;
        }

        @media screen and (min-width: 1300px) and (max-width: 1699px) {
            left: -12%;
            top: 27%;
            border-radius: 50%;
            background-color: #8ddcff;
            width: 725px;
            height: 725px;
        }

        @media screen and (min-width: 1700px) {
            left: -12%;
            top: 27%;
            border-radius: 50%;
            background-color: #8ddcff;
            width: 925px;
            height: 925px;
            padding: 37px;
        }

        .col-1 {
            width: 49%;
            height: 98%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .svg-div {
                position: absolute;
                svg {
                    position: absolute;
                    height: 100%;
                    width: 100%;
                }
                @media screen and (max-width: 699px) {
                    left: 30%;
                    top: 2%;
                    width: 40%;
                    height: 40%;
                }
                @media screen and (min-width: 700px) and (max-width: 999px) {
                    left: 30%;
                    top: 2%;
                    width: 40%;
                    height: 40%;
                }
                @media screen and (min-width: 1000px) and (max-width: 1299px) {
                    left: 30%;
                    top: 2%;
                    width: 40%;
                    height: 40%;
                }
                @media screen and (min-width: 1300px) and (max-width: 1699px) {
                    left: 29%;
                    top: 2%;
                    width: 40%;
                    height: 40%;
                }
                @media screen and (min-width: 1700px) {
                    left: 30%;
                    top: 5%;
                    width: 40%;
                    height: 35%;
                }
            }

            .img-1-div-parent {
                position: relative;

                .img-11-div {
                    z-index: 1;
                    position: absolute;
                    width: 100%;
                    height: 100%;

                    @media screen and (max-width: 699px) {
                        border-radius: 25px;
                    }

                    @media screen and (min-width: 700px) and (max-width: 1699px) {
                        border-radius: 30px;
                    }

                    @media screen and (min-width: 1700px) {
                        border-radius: 35px;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        @media screen and (max-width: 699px) {
                            border-radius: 25px;
                        }

                        @media screen and (min-width: 700px) and (max-width: 1699px) {
                            border-radius: 30px;
                        }

                        @media screen and (min-width: 1700px) {
                            border-radius: 35px;
                        }
                    }
                }

                .img-12-div {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    clip-path: polygon(43% 100%, 37% 83%, 50% 83%);

                    img {
                        width: 100%;
                        height: 100%;
                    }

                    @media screen and (max-width: 699px) {
                        top: 14px;
                    }
                    @media screen and (min-width: 700px) and (max-width: 999px) {
                        top: 16px;
                    }
                    @media screen and (min-width: 1000px) and (max-width: 1299px) {
                        top: 19px;
                    }
                    @media screen and (min-width: 1300px) and (max-width: 1699px) {
                        top: 20px;
                    }
                    @media screen and (min-width: 1700px) {
                        top: 25px;
                    }
                }

                @media screen and (max-width: 699px) {
                    width: 79px;
                    height: 98px;
                    top: -70px;
                    left: 180px;
                    border-radius: 25px;
                }
                @media screen and (min-width: 700px) and (max-width: 999px) {
                    width: 89px;
                    height: 108px;
                    top: -55px;
                    left: 255px;
                    border-radius: 30px;
                }
                @media screen and (min-width: 1000px) and (max-width: 1299px) {
                    top: -55px;
                    left: 325px;
                    width: 99px;
                    height: 118px;
                    border-radius: 30px;
                }
                @media screen and (min-width: 1300px) and (max-width: 1699px) {
                    top: -55px;
                    left: 325px;
                    width: 109px;
                    height: 128px;
                    border-radius: 30px;
                }
                @media screen and (min-width: 1700px) {
                    top: -100px;
                    left: 400px;
                    width: 139px;
                    height: 158px;
                    border-radius: 35px;
                }
            }

            .img-2-div-parent {
                position: relative;
                .img-21-div {
                    z-index: 1;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    // border-radius: 35px;
                    img {
                        width: 100%;
                        height: 100%;
                        @media screen and (max-width: 699px) {
                            border-radius: 25px;
                        }

                        @media screen and (min-width: 700px) and (max-width: 1699px) {
                            border-radius: 30px;
                        }

                        @media screen and (min-width: 1700px) {
                            border-radius: 35px;
                        }
                    }
                    @media screen and (max-width: 699px) {
                        border-radius: 25px;
                    }

                    @media screen and (min-width: 700px) and (max-width: 1699px) {
                        border-radius: 30px;
                    }

                    @media screen and (min-width: 1700px) {
                        border-radius: 35px;
                    }
                }
                .img-22-div {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    clip-path: polygon(43% 100%, 37% 83%, 50% 83%);

                    img {
                        width: 100%;
                        height: 100%;
                        @media screen and (max-width: 699px) {
                            border-radius: 25px;
                        }

                        @media screen and (min-width: 700px) and (max-width: 1699px) {
                            border-radius: 30px;
                        }

                        @media screen and (min-width: 1700px) {
                            border-radius: 35px;
                        }
                    }
                    @media screen and (max-width: 699px) {
                        top: 14px;
                    }
                    @media screen and (min-width: 700px) and (max-width: 999px) {
                        top: 16px;
                    }
                    @media screen and (min-width: 1000px) and (max-width: 1299px) {
                        top: 19px;
                    }
                    @media screen and (min-width: 1300px) and (max-width: 1699px) {
                        top: 20px;
                    }
                    @media screen and (min-width: 1700px) {
                        top: 25px;
                    }
                }

                @media screen and (max-width: 699px) {
                    top: -260px;
                    left: 120px;
                    width: 79px;
                    height: 98px;
                    border-radius: 25px;
                }
                @media screen and (min-width: 700px) and (max-width: 999px) {
                    top: -353px;
                    left: 195px;
                    width: 89px;
                    height: 108px;
                    border-radius: 30px;
                }
                @media screen and (min-width: 1000px) and (max-width: 1299px) {
                    top: -423px;
                    left: 225px;
                    width: 99px;
                    height: 118px;
                    border-radius: 30px;
                }
                @media screen and (min-width: 1300px) and (max-width: 1699px) {
                    top: -483px;
                    left: 255px;
                    width: 109px;
                    height: 128px;
                    border-radius: 30px;
                }
                @media screen and (min-width: 1700px) {
                    top: -580px;
                    left: 290px;
                    width: 139px;
                    height: 158px;
                    border-radius: 35px;
                }
            }
        }
        .col-2 {
            width: 49%;
            height: 95%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .svg-div {
                position: absolute;
                svg {
                    position: absolute;
                    height: 100%;
                    width: 100%;
                }

                @media screen and (max-width: 699px) {
                    left: 55%;
                    top: 37%;
                    width: 40%;
                    height: 40%;
                }
                @media screen and (min-width: 700px) and (max-width: 999px) {
                    left: 55%;
                    top: 38%;
                    width: 40%;
                    height: 40%;
                }
                @media screen and (min-width: 1000px) and (max-width: 1299px) {
                    left: 53%;
                    top: 35%;
                    width: 40%;
                    height: 40%;
                }
                @media screen and (min-width: 1300px) and (max-width: 1699px) {
                    left: 55%;
                    top: 32%;
                    width: 40%;
                    height: 40%;
                }
                @media screen and (min-width: 1700px) {
                    left: 57%;
                    top: 40%;
                    width: 40%;
                    height: 35%;
                }
            }

            .img-1-div-parent {
                position: relative;

                .img-11-div {
                    z-index: 10;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    @media screen and (max-width: 699px) {
                        border-radius: 25px;
                    }

                    @media screen and (min-width: 700px) and (max-width: 1699px) {
                        border-radius: 30px;
                    }

                    @media screen and (min-width: 1700px) {
                        border-radius: 35px;
                    }
                    img {
                        width: 100%;
                        height: 100%;
                        @media screen and (max-width: 699px) {
                            border-radius: 25px;
                        }

                        @media screen and (min-width: 700px) and (max-width: 1699px) {
                            border-radius: 30px;
                        }

                        @media screen and (min-width: 1700px) {
                            border-radius: 35px;
                        }
                    }
                }

                .img-12-div {
                    position: absolute;
                    width: 100%;
                    height: 100%;

                    clip-path: polygon(43% 100%, 37% 83%, 50% 83%);

                    img {
                        width: 100%;
                        height: 100%;
                    }
                    @media screen and (max-width: 699px) {
                        top: 14px;
                    }
                    @media screen and (min-width: 700px) and (max-width: 999px) {
                        top: 16px;
                    }
                    @media screen and (min-width: 1000px) and (max-width: 1299px) {
                        top: 19px;
                    }
                    @media screen and (min-width: 1300px) and (max-width: 1699px) {
                        top: 20px;
                    }
                    @media screen and (min-width: 1700px) {
                        top: 25px;
                    }
                }

                @media screen and (max-width: 699px) {
                    top: 80px;
                    left: 140px;
                    width: 79px;
                    height: 98px;
                    border-radius: 25px;
                }
                @media screen and (min-width: 700px) and (max-width: 999px) {
                    top: 145px;
                    left: 180px;
                    width: 89px;
                    height: 108px;
                    border-radius: 30px;
                }
                @media screen and (min-width: 1000px) and (max-width: 1299px) {
                    top: 165px;
                    left: 200px;
                    width: 99px;
                    height: 118px;
                    border-radius: 30px;
                }
                @media screen and (min-width: 1300px) and (max-width: 1699px) {
                    top: 174px;
                    left: 230px;
                    width: 109px;
                    height: 128px;
                    border-radius: 30px;
                }
                @media screen and (min-width: 1700px) {
                    top: 250px;
                    left: 330px;
                    width: 139px;
                    height: 158px;
                    border-radius: 35px;
                }
            }

            .img-2-div-parent {
                position: relative;
                .img-21-div {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    @media screen and (max-width: 699px) {
                        border-radius: 25px;
                    }

                    @media screen and (min-width: 700px) and (max-width: 1699px) {
                        border-radius: 30px;
                    }

                    @media screen and (min-width: 1700px) {
                        border-radius: 35px;
                    }
                    img {
                        width: 100%;
                        height: 100%;

                        @media screen and (max-width: 699px) {
                            border-radius: 25px;
                        }

                        @media screen and (min-width: 700px) and (max-width: 1699px) {
                            border-radius: 30px;
                        }

                        @media screen and (min-width: 1700px) {
                            border-radius: 35px;
                        }
                    }
                }
                .img-22-div {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    clip-path: polygon(43% 100%, 37% 83%, 50% 83%);

                    img {
                        width: 100%;
                        height: 100%;
                    }

                    @media screen and (max-width: 699px) {
                        top: 14px;
                    }
                    @media screen and (min-width: 700px) and (max-width: 999px) {
                        top: 16px;
                    }
                    @media screen and (min-width: 1000px) and (max-width: 1299px) {
                        top: 19px;
                    }
                    @media screen and (min-width: 1300px) and (max-width: 1699px) {
                        top: 20px;
                    }
                    @media screen and (min-width: 1700px) {
                        top: 25px;
                    }
                }
                @media screen and (max-width: 699px) {
                    top: -100px;
                    left: 40px;
                    width: 79px;
                    height: 98px;
                    border-radius: 25px;
                }
                @media screen and (min-width: 700px) and (max-width: 999px) {
                    top: -143px;
                    left: 45px;
                    width: 89px;
                    height: 108px;
                    border-radius: 30px;
                }
                @media screen and (min-width: 1000px) and (max-width: 1299px) {
                    top: -192px;
                    left: 50px;
                    width: 99px;
                    height: 118px;
                    border-radius: 30px;
                }
                @media screen and (min-width: 1300px) and (max-width: 1699px) {
                    top: -240px;
                    left: 70px;
                    width: 109px;
                    height: 128px;
                    border-radius: 30px;
                }
                @media screen and (min-width: 1700px) {
                    top: -220px;
                    left: 100px;
                    width: 139px;
                    height: 158px;
                    border-radius: 35px;
                }
            }
        }
    }

    .earth-content-div {
        position: relative;
        color: #7b7b7b;
        font-family: -apple-system, BlinkMacSystemFont, sans-serif;
        text-align: left;
        font-weight: bold;
        @media screen and (max-width: 769px) {
            font-size: 20px;
            top: -60%;
            width: 40%;
            line-height: 20px;
            left: 55%;
        }
        @media screen and (min-width: 770px) and (max-width: 1099px) {
            font-size: 23px;
            top: -60%;
            width: 40%;
            line-height: 27px;
            left: 59%;
        }
        @media screen and (min-width: 1100px) and (max-width: 1299px) {
            font-size: 30px;
            top: -40%;
            width: 45%;
            line-height: 35px;
            left: 52%;
        }
        @media screen and (min-width: 1300px) and (max-width: 1699px) {
            font-size: 35px;
            // top: -50%;
            top: -70%;
            width: 45%;
            line-height: 40px;
            left: 50%;
        }
        @media screen and (min-width: 1700px) {
            font-size: 41px;
            top: -45%;
            width: 44%;
            line-height: 49px;
            left: 50%;
        }
    }
}
