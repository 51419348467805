.vibe-container {
    height: inherit;
    width: inherit;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    // width: 100vw;
    // height: 100vh;
    // border:1px solid red;

    .vibe-div {
        margin: auto;
        background-color: #ed752f;
        border-radius: 65px;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        font-weight: bold;
        text-align: center;
        font-family: SF PRO DISPLAY BOLD;
        font-size: 3rem;

        @media (max-width: 720px) {
            font-size: 2.5rem;
        }
        @media (max-width: 600px) {
            font-size: 2.1rem;
        }
        @media (max-width: 500px) {
            font-size: 1.8rem;
        }
        @media (min-width: 1500px) {
            font-size: 2.9rem;
        }
        @media (max-width: 699px) {
            height: 60px;
            width: 130px;
        }
        @media screen and (min-width: 700px) and (max-width: 999px) {
            height: 65px;
            width: 160px;
        }
        @media screen and (min-width: 1000px) and (max-width: 1299px) {
            height: 60px;
            width: 165px;
        }
        @media screen and (min-width: 1000px) and (max-width: 1299px) {
            height: 70px;
            width: 180px;
        }
        @media screen and (min-width: 1300px) and (max-width: 1699px) {
            height: 98px;
            width: 240px;
        }
        @media screen and (min-width: 1700px) {
            height: 118px;
            width: 327px;
        }
    }

    .vibe-content {
        height: auto;
        text-align: center;
        color: #808080;
        font-weight: bold;
        font-family: SF PRO DISPLAY BOLD;
        position: relative;
        color: #808080;
        font-weight: bold;
        text-align: center;
        margin: auto;

        @media screen and (max-width: 699px) {
            margin: 35% auto 0px;
            font-size: 20px;
            width: 93%;
        }
        @media screen and (min-width: 700px) and (max-width: 899px) {
            margin: 35% auto 0px;
            font-size: 20px;
            width: 63%;
        }
        @media screen and (min-width: 900px) and (max-width: 1500px) {
            margin: 23% auto 0px;
            font-size: 20px;
            width: 53%;
        }
        @media screen and (min-width: 1501px) {
            margin: 25% auto 0px;
            font-size: 35px;
            width: 65%;
        }
        @media screen and (min-width: 700px) and (max-width: 1099px) {
            font-size: 20px;
            width: 75%;
        }
        @media screen and (min-width: 1100px) and (max-width: 1399px) {
            font-size: 20px;
            width: 65%;
        }
        @media screen and (min-width: 1400px) and (max-width: 1700px) {
            font-size: 25px;
            width: 55%;
        }
        @media screen and (min-width: 1701px) {
            font-size: 35px;
            width: 65%;
        }
    }
}
