.media-parent-div {
    height: inherit;
    width: inherit;
    // overflow: hidden;
    justify-content: center;
    position: relative;
    // border:1px solid red;
    z-index: 100;
    font-family: SF PRO DISPLAY BOLD;

    .badgeMedia-large-div {
        border: 1px solid #707070;
        position: absolute;
        display: flex;
        justify-content: space-between;
        border-bottom: none;
        height: 90%;

        border-top-left-radius: 13%;
        border-top-right-radius: 13%;

        @media screen and (max-width: 799px) {
            width: 80%;
            left: 12%;
            padding: 20px;
        }

        @media screen and (min-width: 800px) and (max-width: 1199px) {
            width: 65%;
            left: 17%;
            padding: 25px;
        }

        @media screen and (min-width: 1200px) and (max-width: 1700px) {
            width: 55%;
            left: 22%;
            padding: 25px;
        }

        @media screen and (min-width: 1701px) {
            width: 50%;
            padding: 37px;
            left: 25%;
        }

        .col-1 {
            width: 49%;
            // border: 1px solid red;
            height: 98%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .img-1-div {
                overflow: hidden;
                width: 100%;
                position: relative;

                @media screen and (max-width: 699px) {
                    border-radius: 25px;
                }

                @media screen and (min-width: 700px) and (max-width: 1699px) {
                    border-radius: 30px;
                }

                @media screen and (min-width: 1700px) {
                    border-radius: 35px;
                }

                img {
                    width: 100%;
                    height: 100%;

                    @media screen and (max-width: 699px) {
                        border-radius: 25px;
                    }

                    @media screen and (min-width: 700px) and (max-width: 1699px) {
                        border-radius: 30px;
                    }

                    @media screen and (min-width: 1700px) {
                        border-radius: 35px;
                    }
                }
                p {
                    position: absolute;
                    font-weight: bold;
                    color: white;
                    text-align: left;

                    @media screen and (max-width: 699px) {
                        width: 75%;
                        font-size: 25px;
                        top: 25%;
                        left: 10%;
                        line-height: 30px;
                    }
                    @media screen and (min-width: 700px) and (max-width: 1099px) {
                        width: 70%;
                        font-size: 25px;
                        top: 21%;
                        left: 10%;
                        line-height: 35px;
                    }
                    @media screen and (min-width: 1100px) and (max-width: 1399px) {
                        width: 75%;
                        font-size: 30px;
                        top: 20%;
                        left: 10%;
                        line-height: 41px;
                    }
                    @media screen and (min-width: 1400px) and (max-width: 1699px) {
                        width: 71%;
                        font-size: 35px;
                        top: 18%;
                        left: 10%;
                        line-height: 45px;
                    }

                    @media screen and (min-width: 1700px) {
                        width: 75%;
                        font-size: 41px;
                        top: 25%;
                        left: 10%;
                        line-height: 50px;
                    }
                }
            }
            .img-2-div {
                overflow: hidden;
                width: 100%;
                @media screen and (max-width: 699px) {
                    border-top-right-radius: 25px;
                    border-top-left-radius: 25px;
                }

                @media screen and (min-width: 700px) and (max-width: 1699px) {
                    border-top-right-radius: 30px;
                    border-top-left-radius: 30px;
                }

                @media screen and (min-width: 1700px) {
                    border-top-right-radius: 35px;
                    border-top-left-radius: 35px;
                }

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .col-2 {
            width: 49%;
            height: 95%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .img-1-div {
                overflow: hidden;
                position: relative;
                @media screen and (max-width: 699px) {
                    border-radius: 25px;
                }

                @media screen and (min-width: 700px) and (max-width: 1699px) {
                    border-radius: 30px;
                }

                @media screen and (min-width: 1700px) {
                    border-radius: 35px;
                }

                img {
                    width: 100%;
                    height: 100%;
                    @media screen and (max-width: 699px) {
                        border-radius: 25px;
                    }

                    @media screen and (min-width: 700px) and (max-width: 1699px) {
                        border-radius: 30px;
                    }

                    @media screen and (min-width: 1700px) {
                        border-radius: 35px;
                    }
                }

                .para-div {
                    position: absolute;
                    top: 0;
                    left: 10%;
                    // border: 1px solid red;

                    @media screen and (max-width: 699px) {
                        width: 75%;
                    }
                    @media screen and (min-width: 700px) and (max-width: 1099px) {
                        width: 70%;
                    }
                    @media screen and (min-width: 1100px) and (max-width: 1399px) {
                        width: 75%;
                    }
                    @media screen and (min-width: 1400px) and (max-width: 1699px) {
                        width: 71%;
                    }
                    @media screen and (min-width: 1700px) {
                        width: 75%;
                    }

                    p {
                        font-weight: bold;
                        color: white;
                        width: 100%;
                        @media screen and (max-width: 699px) {
                            font-size: 25px;
                            line-height: 30px;
                        }
                        @media screen and (min-width: 700px) and (max-width: 1099px) {
                            font-size: 25px;
                            line-height: 35px;
                        }
                        @media screen and (min-width: 1100px) and (max-width: 1399px) {
                            font-size: 30px;
                            line-height: 41px;
                        }
                        @media screen and (min-width: 1400px) and (max-width: 1699px) {
                            font-size: 35px;
                            line-height: 45px;
                        }
                        @media screen and (min-width: 1700px) {
                            font-size: 41px;
                            line-height: 50px;
                        }
                    }
                }
            }
            .img-2-div {
                overflow: hidden;
                position: relative;
                @media screen and (max-width: 699px) {
                    border-radius: 25px;
                }

                @media screen and (min-width: 700px) and (max-width: 1699px) {
                    border-radius: 30px;
                }

                @media screen and (min-width: 1700px) {
                    border-radius: 35px;
                }
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}
