.knowUnknownWrapper {
  min-height: 100vh;
  background-color: white;
  position: relative;
  border-top-right-radius: 5vw;
  border-top-left-radius: 5vw;
  margin-top: -5vw;
  z-index: 9;
  /* background-image: linear-gradient(gray, white); */
}

.knowUnknownSection {
  text-align: center;
  position: absolute;
  top: 35vh;
  left: 55vw;
  width: 30vw;
  font-family: monospace;
  color: #1c3f62;
}

.knowUnknownSection h1 {
  font-size: 42px;
  margin-bottom: 3vh;
  font-family: "Ubuntu-bold";
}

.knowUnknownSection span {
  font-family: "Ubuntu";
  font-size: 28px;
}

#imagesequence {
  background: #fff;
  width: 100%;
}

#imagesequence img {
  display: block;
  max-width: 300px;
  margin: 0 auto;
}

.faqBox p {
  color: #194067;
}

.highlightText {
  color: rgb(253, 141, 44);
}

@media (max-width: 600px) {
  .topSection {
    min-height: 40vh;
    padding-bottom: 10vh;
    margin-top: 45px;
  }

  .knowUnknownWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 80vh;
  }
  .knowUnknownSection {
    position: static;
    padding-bottom: 10vh;
    width: 70vw;
  }
  .imageSequencer5Vibe {
    margin-left: 0 !important;
    width: 100% !important;
  }
  .imageSequenceContainer {
    height: 50vh !important;
    margin-right: -55vw;
  }

  .knowUnknownSection span {
    font-size: 18px;
  }
}
