.footer {
  width: inherit;
  background-color: #f7f7f7;
  padding: 1.5rem 8%;
  font-family: "Ubuntu";
  font-style: normal;
}
.upperFooter {
  display: flex;
  border-bottom: 1px solid #dddddd;
  padding-bottom: 2rem;
}
.infoFooter {
  width: 65%;
  display: grid;
  grid-template-columns: auto auto auto auto auto;
}
.upperFooter ul {
  list-style: none;
}
.upperFooter li {
  padding: 0.4rem 0rem;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.1rem;
  color: #464646;
}
.upperFooter h3 {
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2.1rem;
  color: #464646;
}
.infoFooter a {
  cursor: pointer;
  text-decoration: none;
  color: #464646;
}
.storeFooter {
  width: 35%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.storeFooter div:nth-child(1) {
  margin-right: 0.8rem;
}
.storeFooter img {
  cursor: pointer;
  object-fit: contain;
  width: 100%;
  max-width: 16rem;
}
.bottomFooter {
  margin: 1rem 0rem;
  display: flex;
  justify-content: space-between;
}
.copyrightContent {
  display: flex;
  align-items: center;
}
.copyrightContent span {
  margin-right: 1.5rem;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 1.1rem;
  line-height: 1.9rem;
  color: #464646;
}
.copyrightContent ul {
  display: flex;
}
.copyrightContent li:nth-child(1) {
  margin-right: 2rem;
}
.copyrightContent li {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 1.1rem;
  line-height: 1.9rem;
  color: #464646;
}
.mediaContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 2rem;
}
.logoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.logoContainer img {
  cursor: pointer;
  object-fit: contain;
  width: 1.5rem;
  height: 1.5rem;
  max-width: 6rem;
  max-height: 6rem;
}

@media (max-width: 600px) {
  .footer {
    padding: 1.5rem 5%;
  }
  .upperFooter {
    display: flex;
    flex-direction: column;
    border-bottom: none;
  }
  .infoFooter {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .storeFooter {
    margin-top: 1rem;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
  }
  .storeFooter img {
    max-width: 35rem;
  }
  .storeFooter div:nth-child(1) {
    margin-right: 0;
  }
  .bottomFooter {
    display: flex;
    flex-direction: column-reverse;
  }
  .mediaContainer {
    display: flex;
    justify-content: center;
  }
  .copyrightContent {
    justify-content: center;
    margin: 1rem 0rem;
  }
  .copyrightContent span {
    margin-right: 2.3rem;
    font-size: 1.4rem;
  }
  .copyrightContent li {
    font-size: 1.4rem;
  }
}
@media (max-width: 420px) {
  .storeFooter {
    justify-content: space-between;
  }
}
