.supportPage {
  padding: 2rem 8%;
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.04) inset;
  background-color: #ffffff;
  font-family: Ubuntu;
  font-style: normal;
  min-height: 80vh;
}
.supportHeader {
  display: flex;
  justify-content: space-between;
}
.supportText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  height: 100%;
  width: 50%;
  padding-top: 4rem;
}
.supportTextDuplicate {
  display: none;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  height: 100%;
  width: 50%;
  padding-top: 4rem;
}
.supportText h2,
.supportTextDuplicate h2 {
  width: 100%;
  height: 100%;
  font-family: "Ubuntu-Medium";
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 2.8rem;
  color: #000000;
  padding-bottom: 4rem;
}
.supportText p,
.supportTextDuplicate p {
  height: 100%;
  width: 100%;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.1rem;
  color: #464646;
}
.supportText span,
.supportTextDuplicate span {
  padding-bottom: 2rem;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.1rem;
  color: #464646;
}
.vectorContainer {
  width: 50%;
}
.vectorContainer img {
  object-fit: contain;
  width: 100%;
  max-width: 65rem;
}
.faqContainer {
  margin: 5rem 0rem 8rem;
}
.faqContainer h2 {
  font-family: "Ubuntu-Medium";
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 2.8rem;
  color: #464646;
}
.dummyLine {
  margin-top: 4rem;
  border-bottom: 0.868903px solid #dddddd;
  height: 0px;
}
.questionContainer {
  display: flex;
  flex-direction: column;
}
@media (max-width: 1500px) {
  .vectorContainer {
    margin-top: 1rem;
  }
  .vectorContainer img {
    transform: scale(1.1);
  }
}
@media (max-width: 1200px) {
  .vectorContainer {
    margin-top: 3rem;
  }
}
@media (max-width: 1000px) {
  .supportText h2 {
    font-size: 2.6rem;
    line-height: 1rem;
  }
  .supportText p {
    line-height: 2.3rem;
  }
  .supportText span {
    line-height: 2.3rem;
  }
  .vectorContainer {
    margin-top: 2rem;
  }
  .vectorContainer img {
    transform: scale(1.1);
  }
}
@media (max-width: 870px) {
  .supportHeader {
    display: flex;
    flex-direction: column-reverse;
  }
  .supportText {
    margin-top: 0rem;
    width: 100%;
  }
  .vectorContainer img {
    width: 80%;
    transform: scale(1);
  }
  .vectorContainer {
    margin-top: 2rem;
    width: 100%;
    text-align: center;
  }
}
@media (max-width: 720px) {
  .supportPage {
    padding: 0rem 5%;
  }
  .supportText {
    display: none;
  }
  .faqContainer {
    margin: 3rem 0rem 1rem;
  }
  .supportTextDuplicate {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    height: 100%;
    width: 100%;
    padding: 4rem 0rem;
  }

  .supportTextDuplicate h2 {
    width: 100%;
    height: 100%;
    font-family: "Ubuntu-Medium";
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 2.8rem;
    color: #000000;
    padding-bottom: 2rem;
  }
  .supportTextDuplicate p {
    height: 100%;
    width: 100%;
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 2.1rem;
    color: #464646;
  }
  .supportTextDuplicate span {
    padding-bottom: 2rem;
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 2.1rem;
    color: #464646;
  }
  .vectorContainer img {
    width: 80%;
  }
}
@media (max-width: 500px) {
  .vectorContainer img {
    width: 100%;
  }
}
@media (min-width: 1500px) {
  .vectorContainer img {
    transform: scale(0.9);
  }
}
