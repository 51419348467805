* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
@font-face {
  font-family: "SF PRO DISPLAY BOLD";
  src: url("./component/fonts/SFPRODISPLAYBOLD.OTF") format("opentype");
}
@font-face {
  font-family: "Ubuntu";
  src: url("./component/fonts/Ubuntu-Regular.woff") format("woff");
}
@font-face {
  font-family: "Inter";
  src: url("./component/fonts/Inter-Regular.woff") format("woff");
}
@font-face {
  font-family: "Ubuntu-Medium";
  src: url("./component/fonts/Ubuntu-Medium.woff") format("woff");
}

.app {
  width: 100%;
}
